/* istanbul ignore file */
import newconfig from './config.json'
const config = {}
export default config

async function load() {
  for (const prop in config) {
    delete config[prop]
  }
  for (const prop in newconfig) {
    config[prop] = newconfig[prop]
  }
  return config
}
export { load }
