/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this file, we configured URL and componenet for that URL
  #lazy           : Lazy loading is a technique that enables us to load a specific component when a particular route is accessed. 
                    It exponentially enhances the load time and the loading speed. At the same time, it increases the react application performance.
  #React.Suspense : React Suspense here to offer a fallback React element when the component is lazy loaded from the server.
*/

import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import LoadingComponent from '../components/base/loadingcomponent/LoadingComponent'
import { useIsAuthenticated } from '@azure/msal-react'
import CreateNewJobs from '../pages/Jobs/Jobs/CreateNewJobs'

const Layout = lazy(() => import('../pages/layoutPage/Layout'))
const Contact = lazy(() => import('../pages/contactPage/ContactPage'))
const LandingPage = lazy(() => import('../pages/landingPage/LandingPage'))

const Career = lazy(() => import('../pages/careerpage/CareerPage'))

const CulturePage = lazy(() => import('../pages/culturepage/CulturePage'))

const CloudService = lazy(() =>
  import('../pages/Services/cloudservices/CloudServices')
)
const Experiencedesign = lazy(() =>
  import('../pages/Services/experienceDesign/ExperienceDesign')
)

const QualityAssurance = lazy(() =>
  import('../pages/Services/qualityassurance/QualityAssurance')
)
const ProductDevelopement = lazy(() =>
  import('../pages/Services/productdevelopement/ProductDevelopement')
)
const ArtificialIntelligence = lazy(() =>
  import('../pages/Services/artificialintelligence/ArtificialIntelligence')
)
const About = lazy(() => import('../pages/aboutpage/AboutPage'))
const PrivacyPolicies = lazy(() =>
  import('../pages/aboutpage/privacypolicies/PrivacyPolicies')
)
const CareerDescription = lazy(() =>
  import('../pages/careerpage/careerdescription/CareerDescription')
)
const LoginPage = lazy(() => import('../pages/Login/Login'))
const NotFound = lazy(() => import('../components/base/notfound/NotFound'))
const Jobs = lazy(() => import('../pages/Jobs/Jobs/Jobs'))

// A single Suspense wrapper for fallback
const withSuspense = (Component) => {
  return (
    <React.Suspense fallback={<LoadingComponent />}>
      <Component />
    </React.Suspense>
  )
}

const RoutesFile = () => {
  return (
    <React.Suspense fallback={<LoadingComponent />}>
      <Routes>
        <Route exact path="/" element={<Layout />} caseSensitive>
          <Route exact path="/" element={<LandingPage />} caseSensitive />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/culture" element={<CulturePage />} />
          <Route exact path="/cloudservices" element={<CloudService />} />
          <Route
            exact
            path="/qualityassurance"
            element={<QualityAssurance />}
          />
          <Route
            exact
            path="/productdevelopement"
            element={<ProductDevelopement />}
          />
          <Route
            exact
            path="/artificialintelligence"
            element={<ArtificialIntelligence />}
          />
          <Route
            exact
            path="/experiencedesign"
            element={<Experiencedesign />}
          />
          <Route exact path="/privacypolicies" element={<PrivacyPolicies />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/career/:id" element={<CareerDescription />} />
        </Route>
        <Route exact path="*" element={<NotFound />} />
        <Route exact path="/career/login" element={<NotFound />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/jobs" element={<Jobs />} />{' '}
        <Route exact path="/CreateNewjob" element={<CreateNewJobs />} />
        <Route exact path="/Editjob/:Id" element={<CreateNewJobs />} />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesFile
