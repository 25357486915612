import React, { useState } from 'react'
import { Snackbar, Alert, Slide } from '@mui/material'
import { toastMessage } from '../../recoil/atoms/atoms'
import { useRecoilState } from 'recoil'

const ToastMessage = ({ errorMessage, clearError }) => {
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToastOpen(null)
  }

  return (
    <div>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={SlideTransition}
        sx={{
          marginTop: { xs: '110px', sm: '50px', md: '65px' },
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {toastOpen || 'An error occurred. Please try again later.'}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ToastMessage
