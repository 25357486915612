import {
  funcAppApiUrl,
  redirectApiUrl,
} from '../../views/components/recoil/atoms/atoms'
import { useRecoilValue } from 'recoil'

const useAPIEndpoints = () => {
  const api = useRecoilValue(redirectApiUrl)
  const funcApi = useRecoilValue(funcAppApiUrl)

  const APIEndPoints = {
    getJobRoleDetails: {
      method: 'get',
      url: `${api}JobRole/JobRoleDetails`,
    },
    getJobTypeDetails: {
      method: 'get',
      url: `${api}JobDetails/JobTypeDetails`,
    },
    GetJobDetails: {
      method: 'get',
      url: `${funcApi}getalljobs?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    GetResumeDetails: {
      method: 'get',
      url: `${funcApi}GetAllResumes?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    DeleteJobRoleDetails: {
      method: 'Delete',
      url: `${api}JobRole/ModifyJobRoleDetailsStatus`,
    },
    DeleteAllJobRoleDetails: {
      method: 'Delete',
      url: `${api}JobRole/ModifyAllJobRoleDetailsStatus`,
    },
    GetResumeStatus: {
      method: 'Get',
      url: `${api}Resume/ResumeStatusDetails?api-version=1`,
    },
    GetJobDetailsById: {
      method: 'get',
      url: `${funcApi}GetJob?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    getStatusOptions: {
      method: 'get',
      url: `${api}JobDetails/JobStatusDetails`,
    },
    PostJobDetails: {
      method: 'Post',
      url: `${funcApi}CreateJob?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    UpdateJobDetails: {
      method: 'Put',
      url: `${funcApi}UpdateJob?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    // VerifyUsers: {
    //   method: 'Post',
    //   url: `${api}Login/GetUserDetails`,s
    // },
    UpdateJobRoleDetails: {
      method: 'Put',
      url: `${api}JobRole/ModifyJobRoleDetails`,
    },
    PostJobRoleDetails: {
      method: 'Post',
      url: `${api}JobRole/JobRoleDetails`,
    },
    UpdateResumeStatus: {
      method: 'Put',
      url: `${funcApi}UpdateResumeStatus?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    deleteBulkJobRole: {
      method: 'Delete',
      url: `${api}JobDetails/ModifyAllJobDetailsStatus`,
    },
    deleteJobRole: {
      method: 'PUT',
      url: `${funcApi}DeleteJob?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    careerPost: {
      method: 'post',
      url: `${funcApi}SubmitResume?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
    PostContactDetails: {
      method: 'post',
      url: `${funcApi}CreateContact?code=OnrKzFcOCkpg8_zZlJoImw7g-mG0IGiW0N6ncWigsyGYAzFukPomFQ==`,
    },
  }
  return APIEndPoints
}

export default useAPIEndpoints
