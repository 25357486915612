import React from 'react'
import Button from '@mui/material/Button'
import './Button.css'

const isMobile = window.innerWidth <= 550
function ButtonComponent({
  variant,
  color,
  text,
  label,
  className,
  onClick,
  startIcon,
  disabled,
  size,
  endIcon,
  image,
}) {
  return (
    <Button
      variant={variant}
      color={color}
      label={label}
      className={`button_type_${className}`}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      style={{
        lineHeight:
          text === 'Sign Out' ? (isMobile ? '15px' : '26px') : undefined,
      }}
    >
      {image && (
        <img
          src={image}
          alt=""
          style={{
            marginRight: '10px',
          }}
        />
      )}

      {text}
    </Button>
  )
}

export default ButtonComponent
