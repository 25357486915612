import CryptoJS from 'crypto-js'

const decryptData = (encryptedData) => {
  try {
    if (!encryptedData) {
      console.error('Decryption failed: No data received')
      return null
    }

    // Convert back to standard Base64
    const base64 = encryptedData.replace(/-/g, '+').replace(/_/g, '/')

    const bytes = CryptoJS.AES.decrypt(base64, 'LVWebsite')
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8)

    if (!decryptedText) {
      console.error('Decryption failed: Invalid data')
      return null
    }

    return decryptedText
  } catch (error) {
    console.error('Decryption failed:', error)
    return null
  }
}

export default decryptData
