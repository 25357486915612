// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-main-container {
  padding: 30px 30px;
}

.delete-popup {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #ffffff;
  color: #d70404;
  padding: 8px 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 14px;
  z-index: 1000;
}
.delete-popup button {
  background: none;
  border: none;
  color: #d70404;
  text-transform: none;
  cursor: pointer;
  border: 1px solid #d1d1d1;
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/Jobs/Jobs/createjobrole/CreateJobRole.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,wCAAwC;EACxC,eAAe;EACf,eAAe;EACf,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".slider-main-container {\n  padding: 30px 30px;\n}\n\n.delete-popup {\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  background: #ffffff;\n  color: #d70404;\n  padding: 8px 12px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n  font-size: 14px;\n  z-index: 1000;\n}\n.delete-popup button {\n  background: none;\n  border: none;\n  color: #d70404;\n  text-transform: none;\n  cursor: pointer;\n  border: 1px solid #d1d1d1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
