import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { signOut } from '../../../../app/authService'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  blobImageUrl,
  signInClicked,
  userAccessAtom,
} from '../../recoil/atoms/atoms'
import ButtonComponent from '../../base/button/Button'
import { useMsal } from '@azure/msal-react'

const AdminHeader = () => {
  const BlobURL = useRecoilValue(blobImageUrl)
  const { instance } = useMsal()
  const setClickedSignInBTN = useSetRecoilState(signInClicked)
  const setUserAccess = useSetRecoilState(userAccessAtom)

  const signoutHandler = () => {
    setClickedSignInBTN('fromLogout')
    setUserAccess('fromLogout')
    signOut(instance)
  }

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingInline: '24px',
      }}
    >
      <Toolbar
        sx={{
          width: '80%',
          marginBlock: '10px',
          '&.MuiToolbar-root': {
            padding: 0,
          },
        }}
      >
        <Typography
          component="div"
          sx={{
            cursor: 'pointer',
            height: '46px',
          }}
        >
          <img
            src={`${BlobURL}/Images/logicvalley-headerlogo.svg`}
            width={175}
            height={46}
            alt="logicvalley"
            onClick={() => {
              navigate('/')
            }}
            style={{
              objectFit: 'contain',
              height: 'auto',
            }}
          />
        </Typography>
      </Toolbar>
      <div
        className="submit-btn"
        style={{
          marginBlock: '10px',
          minWidth: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonComponent
          text="Sign Out"
          variant="contained"
          onClick={signoutHandler}
        />
      </div>
    </AppBar>
  )
}
export default AdminHeader
