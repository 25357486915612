// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-newJob-main {
}

.newJob-container {
  background-color: #ffffff;
  padding: 30px;
}

.autolabel-name {
  padding-bottom: 4px;
  padding-top: 10px;
}

.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {
  width: 30% !important;
}

.css-1a7umsy-MuiFormHelperText-root.Mui-error {
  margin-top: 0px;
}

.button_type_rolebutton {
  text-transform: none;
  color: #005c84;
  border-radius: 18px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.backButton {
  background-color: transparent;
  color: #1f3c88;
}
.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-xs-12.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
}
.notranslate.public-DraftEditor-content {
  font-family: 'Roboto';
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/Jobs/Jobs/CreateNewJobs.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,6BAA6B;EAC7B,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".create-newJob-main {\n}\n\n.newJob-container {\n  background-color: #ffffff;\n  padding: 30px;\n}\n\n.autolabel-name {\n  padding-bottom: 4px;\n  padding-top: 10px;\n}\n\n.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {\n  width: 30% !important;\n}\n\n.css-1a7umsy-MuiFormHelperText-root.Mui-error {\n  margin-top: 0px;\n}\n\n.button_type_rolebutton {\n  text-transform: none;\n  color: #005c84;\n  border-radius: 18px;\n}\nbutton.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.backButton {\n  background-color: transparent;\n  color: #1f3c88;\n}\n.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-xs-12.buttonContainer {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  border-radius: 8px;\n}\n.notranslate.public-DraftEditor-content {\n  font-family: 'Roboto';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
