import { Button, Grid2, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextBox from '../../../../components/base/textBox/TextField'
import closeicon from '../../../../../assets/image/Close_btn.svg'
import './CreateJobRole.css'
import TableDataGrid from '../../../../components/base/grid/Grid'
import {
  getErrorCode,
  getUserId,
  loader,
} from '../../../../components/recoil/atoms/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import fetchSelector from '../../../../components/recoil/selectors/selectors'
import useApiEndPoints from '../../../../../models/api/apiEndpoints'
import Edit from '../../../../../assets/image/Grid-editIcon.svg'
import Delete from '../../../../../assets/image/Grid-deleteicon.svg'
import fulldelete from '../../../../../assets/image/fulldelete.jpg'
import ButtonComponent from '../../../../components/base/button/Button'

function CreateJobRole({
  setPanContent,
  setPanStatus,
  setToastShown,
  setToastOpen,
  setDeletedid,
  setConfirmDialogOpen,
  setjobroleTrigger,
  jobroleTrigger,
  setSelectedRowIds,
  selectedRowIds,
  setConfirmDialogAction,
  getjobRole,
}) {
  const [formData, setFormData] = useState({ rolevalues: '' })
  const userId = useRecoilValue(getUserId)
  const [loading, setLoading] = useRecoilState(loader)
  const getData = useRecoilValue(fetchSelector)
  const APIEndPoints = useApiEndPoints()
  const [data, setdata] = useState([])
  const [id, setid] = useState('')
  const [errors, setErrors] = useState({ rolevalues: '' })
  const [isSaveClick, setisSaveClick] = useState(false)
  const [globalerror, setGlobalError] = useRecoilState(getErrorCode)

  const handleRole = (e) => {
    const updatedFormData = {
      ...formData,
      rolevalues: e.target.value,
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  useEffect(() => {
    getjobRoleDetails()
  }, [jobroleTrigger])

  const getjobRoleDetails = async () => {
    await getData(
      APIEndPoints.getJobRoleDetails.method,
      APIEndPoints.getJobRoleDetails.url
    )
      .then((resp) => {
        if (resp && resp.data) {
          setjobroleTrigger(false)
          const { message, statusCode } = resp.data
          if (statusCode !== 200 && statusCode !== 409) {
            setGlobalError(statusCode)
          }
          setdata(
            resp.data.data?.map((role) => {
              return {
                id: role.jobRoleId,
                roleName: role.jobRoleName || '',
              }
            })
          )
        }
      })
      .catch((err) => {
        setLoading(false)
        setGlobalError(202)
      })
  }

  const handleEdit = async (id) => {
    setid(id)
    setLoading(true)
    await getData(
      APIEndPoints.GetJobRoleById.method,
      `${APIEndPoints.GetJobRoleById.url}/${id}`
    )
      .then((resp) => {
        if (resp && resp.data) {
          console.log(resp.data.data, 'id data')
          setLoading(false)
          const { message, statusCode } = resp.data
          if (statusCode !== 200 && statusCode !== 409) {
            setGlobalError(statusCode)
          }
          setFormData({ ...formData, rolevalues: resp.data.data?.jobRoleName })
        }
      })
      .catch((err) => {
        setLoading(false)
        setGlobalError(202)
      })
  }

  const validateForm = (values) => {
    let errors = {}
    if (!values.rolevalues.trim()) {
      errors.rolevalues = 'Role Name is required'
    } else if (values.rolevalues.trim().length < 3) {
      errors.rolevalues = 'Role Name must be at least 3 characters.'
    } else if (values.rolevalues.trim().length > 100) {
      errors.rolevalues = 'Role Name must not exceed 100 characters.'
    } else if (!/^[A-Za-z\s]+$/.test(values.rolevalues.trim())) {
      errors.rolevalues = 'Role Name can only contain letters.'
    }
    return errors
  }

  const handleSave = async (e) => {
    e.preventDefault()
    setisSaveClick(true)
    setLoading(true)

    const errorval = validateForm(formData)
    setErrors(errorval)

    if (Object.keys(errorval).length > 0) {
      console.log('Validation failed:', errorval)
      setLoading(false)
      return
    }

    try {
      if (id !== '') {
        const Editjobrole = {
          userId: userId,
          jobRoleId: id,
          jobRoleName: formData.rolevalues,
        }
        await getData(
          APIEndPoints.UpdateJobRoleDetails.method,
          APIEndPoints.UpdateJobRoleDetails.url,
          Editjobrole
        )
          .then((resp) => {
            if (resp && resp.data) {
              setErrors({})
              setLoading(false)
              setisSaveClick(false)
              setFormData({ ...formData, rolevalues: '' })
              setid('')
              getjobRoleDetails()
              getjobRole()
              const { message, statusCode } = resp.data
              if (statusCode !== 200 && statusCode !== 409) {
                setGlobalError(statusCode)
              }
              if (statusCode === 200) {
                setToastOpen(true)
                setToastShown({
                  success: true,
                  message: resp.data.message,
                })
              }
              if (statusCode === 409) {
                setToastOpen(true)
                setToastShown({
                  success: false,
                  pageNamealready: true,
                  message: resp.data.message,
                })
                return
              }
            }
          })
          .catch((err) => {
            setLoading(false)
            setGlobalError(202)
            setErrors({})
          })
      } else {
        const createjobrole = {
          userId: userId,
          jobRoleName: formData.rolevalues,
        }

        await getData(
          APIEndPoints.PostJobRoleDetails.method,
          APIEndPoints.PostJobRoleDetails.url,
          createjobrole
        )
          .then((resp) => {
            if (resp && resp.data) {
              setErrors({})
              setisSaveClick(false)
              setLoading(false)
              setFormData({ ...formData, rolevalues: '' })
              setid('')
              getjobRoleDetails()
              getjobRole()
              const { message, statusCode } = resp.data
              if (statusCode !== 200 && statusCode !== 409) {
                setGlobalError(statusCode)
              }
              if (statusCode === 200) {
                setToastOpen(true)
                setToastShown({
                  success: true,
                  message: resp.data.message,
                })
              }
              if (statusCode === 409) {
                setToastOpen(true)
                setToastShown({
                  success: false,
                  pageNamealready: true,
                  message: resp.data.message,
                })
                return
              }
            }
          })
          .catch((err) => {
            setLoading(false)
            setGlobalError(202)
            setErrors({})
          })
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const handleDelete = (rowid) => {
    setConfirmDialogOpen(true)
    setConfirmDialogAction('singledelete')
    setDeletedid(rowid)
  }

  const Rolecolumns = [
    {
      field: 'roleName',
      headerName: 'Role Name',
      headerClassName: 'mui-header',
      width: 250,
      editable: true,
    },
    {
      field: '',
      headerName: 'Action',
      headerClassName: 'mui-header',
      width: 100,
      editable: true,
      renderCell: ({ row }) => {
        const Disabled = selectedRowIds.includes(row.id)
        return (
          <div className="user-GridIcons">
            <img
              className="user-Edit"
              style={{
                cursor: Disabled ? 'not-allowed' : 'pointer',
                opacity: Disabled ? 0.5 : 1,
                pointerEvents: Disabled ? 'none' : 'auto',
              }}
              onClick={(event) => {
                if (!Disabled) {
                  event.stopPropagation()
                  handleEdit(row.id)
                }
              }}
              src={Edit}
              alt="Edit"
            />
            <img
              className="user-Delete"
              style={{
                cursor: Disabled ? 'not-allowed' : 'pointer',
                opacity: Disabled ? 0.5 : 1,
                pointerEvents: Disabled ? 'none' : 'auto',
              }}
              onClick={(event) => {
                if (!Disabled) {
                  event.stopPropagation() // Prevents row selection
                  handleDelete(row.id)
                }
              }}
              src={Delete}
              alt="Delete"
            />
          </div>
        )
      },
    },
  ]

  const handleRowSelection = (newSelection) => {
    setSelectedRowIds(newSelection)
  }

  console.log(selectedRowIds, 'selectedRowIds')

  const handleBulkDelete = async () => {
    setConfirmDialogOpen(true)
    setConfirmDialogAction('BulkDelete')
  }

  return (
    <div className="slider-main-container">
      <Grid2 container spacing={2}>
        <Grid2 item size={6}>
          <Typography variant="h5" fontWeight={400} color="#42425A">
            Roles
          </Typography>
        </Grid2>
        <Grid2
          item
          size={6}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <img
            className="close-icon"
            onClick={() => {
              setPanContent(false)
              setPanStatus(false)
              setSelectedRowIds([])
            }}
            style={{ cursor: 'pointer' }}
            alt="close-img"
            src={closeicon}
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2} sx={{ mt: 5, display: 'flex' }}>
        <Grid2 item size={8} sx={{ mt: '-10px' }}>
          <TextBox
            placeholder="Enter role name"
            fieldName="RoleName"
            className="UserRole"
            value={formData.rolevalues}
            onchangeHandler={handleRole}
            error={errors.rolevalues}
            errorText={errors.rolevalues}
            maxLength={50}
          />
        </Grid2>
        <Grid2 item size={4}>
          <Button
            variant="contained"
            size="medium"
            type="submit"
            sx={{
              color: '#2C95B5',
              fontWeight: '500',
              backgroundColor: '#005C84',
              color: '#fff',
              borderRadius: 2,
            }}
            onClick={handleSave}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </Grid2>
      </Grid2>
      <div style={{ marginTop: '35px', position: 'relative' }}>
        {selectedRowIds.length > 0 && (
          <div className="delete-popup">
            <ButtonComponent
              image={fulldelete}
              text={`Delete`}
              size="medium"
              onClick={handleBulkDelete}
            />
          </div>
        )}
        <TableDataGrid
          checkboxSelection
          rowData={data}
          columnData={Rolecolumns}
          rowsperPageCount={10}
          onRowSelectionChange={handleRowSelection}
        />
      </div>
    </div>
  )
}

export default CreateJobRole
