import React from 'react'
import { Dialog, Typography, Box } from '@mui/material'
import ButtonComponent from '../button/Button'
import './ConfirmationDialog.css'

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          padding: '32px',
          maxWidth: '600px',
        },
      }}
    >
      <Typography variant="h5" fontWeight={400} color="#42425A">
        {title}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          marginTop: '14px',
        }}
        color="#42425A"
      >
        This action is Permanent and cannot be undo.
      </Typography>

      <Box sx={{ display: 'flex', gap: '10px', marginTop: 5 }}>
        <ButtonComponent
          variant="outlined"
          size="medium"
          type="submit"
          text="Cancel"
          className="cancel"
          onClick={onClose}
        />
        <ButtonComponent
          variant="contained"
          size="medium"
          type="submit"
          className="locationsave"
          onClick={onConfirm}
          text=" Delete"
        />
      </Box>
    </Dialog>
  )
}

export default ConfirmationDialog
