import { msalInstance } from './msalConfig'
import {
  InteractionType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser'

const loginRequest = {
  scopes: ['User.Read'], // Add necessary scopes
}

export const signIn = async () => {
  try {
    const response = await msalInstance.loginRedirect(loginRequest)
    console.log('response', response)
    sessionStorage.setItem('accessToken', response?.accessToken)
    return response.account
  } catch (error) {
    console.error('Login failed:', error)
  }
}

export const signOut = () => {
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: '/#/login',
  })
}

export const getAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts()
  console.log('accounts', accounts)

  if (accounts.length === 0) return null

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    return response.accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then((response) => response.accessToken)
    } else {
      console.error('Token acquisition failed:', error)
    }
  }
}
