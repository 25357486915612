import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import './Grid.css'

function loadServerRows(page, rowData, rowsperPageCount) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        rowData.slice(page * rowsperPageCount, (page + 1) * rowsperPageCount)
      )
    }, Math.random() * (600 - 100) + 100)
  })
}

export default function TableDataGrid({
  columnData,
  rowData,
  rowsperPageCount = 10,
  onRowSelectionChange,
  checkboxSelection,
}) {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: rowsperPageCount,
  })
  const [rows, setRows] = React.useState([])
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  console.log('rowData-->', rowData)

  React.useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    ;(async () => {
      try {
        const newRows = await loadServerRows(
          paginationModel.page,
          rowData,
          paginationModel.pageSize
        )
        if (!signal.aborted) {
          setRows(newRows)
        }
      } catch (error) {
        if (signal.aborted) return
      }
    })()

    return () => {
      controller.abort()
    }
  }, [paginationModel, rowData])

  const handleRowSelectionChange = (newSelection) => {
    // Pass selected IDs to the parent
    if (onRowSelectionChange) {
      setRowSelectionModel(newSelection)
      onRowSelectionChange(newSelection)
    }
  }
  function NoRowsOverlay() {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No records found
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none', // Remove outer border
          },
        }}
        rowHeight={40}
        // columnHeaderHeight={40}
        rows={rows}
        columns={columnData}
        pagination
        checkboxSelection={checkboxSelection ? true : false}
        paginationModel={paginationModel}
        rowCount={rowData?.length}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        rowSelectionModel={rowSelectionModel}
        hideFooter={rowData?.length <= (rowsperPageCount ?? 10)}
        isCellEditable={() => false}
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </div>
  )
}
