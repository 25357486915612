// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slidingPane-slider .slide-pane__header {
  display: none;
}
.slidingPane-slider.SliderChildComponent {
  height: 500px;
  width: 76% !important;
}
.slide-pane__content {
  padding: 0 !important;
}
.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {
  width: 30% !important;
}
.slide-pane__overlay.overlay-after-open {
  z-index: 1010;
}
.slide-pane.slide-pane_from_right.slidingPane-slider.communication.content-after-open {
  width: 25% !important;
}
.slider-component {
  height: 100vh;
}
/* Override SlidingPane styles for smooth transition */
.slidingPane-slider {
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.slidingPane-slider.is-open {
  transform: translateX(0); /* Fully visible */
  opacity: 1;
}

.slidingPane-slider.is-closed {
  transform: translateX(100%); /* Slide out to the right */
  opacity: 0;
}

.some-custom-overlay-class {
  transition: opacity 0.4s ease; /* Fade in/out effect for the overlay */
}

.some-custom-overlay-class.is-open {
  opacity: 1;
}

.some-custom-overlay-class.is-closed {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/slidingPane/SlidingPane.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA,sDAAsD;AACtD;EACE,kDAAkD;AACpD;;AAEA;EACE,wBAAwB,EAAE,kBAAkB;EAC5C,UAAU;AACZ;;AAEA;EACE,2BAA2B,EAAE,2BAA2B;EACxD,UAAU;AACZ;;AAEA;EACE,6BAA6B,EAAE,uCAAuC;AACxE;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".slidingPane-slider .slide-pane__header {\n  display: none;\n}\n.slidingPane-slider.SliderChildComponent {\n  height: 500px;\n  width: 76% !important;\n}\n.slide-pane__content {\n  padding: 0 !important;\n}\n.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {\n  width: 30% !important;\n}\n.slide-pane__overlay.overlay-after-open {\n  z-index: 1010;\n}\n.slide-pane.slide-pane_from_right.slidingPane-slider.communication.content-after-open {\n  width: 25% !important;\n}\n.slider-component {\n  height: 100vh;\n}\n/* Override SlidingPane styles for smooth transition */\n.slidingPane-slider {\n  transition: transform 0.4s ease, opacity 0.4s ease;\n}\n\n.slidingPane-slider.is-open {\n  transform: translateX(0); /* Fully visible */\n  opacity: 1;\n}\n\n.slidingPane-slider.is-closed {\n  transform: translateX(100%); /* Slide out to the right */\n  opacity: 0;\n}\n\n.some-custom-overlay-class {\n  transition: opacity 0.4s ease; /* Fade in/out effect for the overlay */\n}\n\n.some-custom-overlay-class.is-open {\n  opacity: 1;\n}\n\n.some-custom-overlay-class.is-closed {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
