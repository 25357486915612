import React, { Component, useState } from 'react'
import './SlidingPane.css'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { render } from 'react-dom'
import { useRecoilState } from 'recoil'
import {
  isPaneOpen,
  jobTitleSlide,
  runNowSlide,
} from '../../recoil/atoms/atoms'

function Slider({ SliderComponent, className, sliderName }) {
  const [panStatus, setPanStatus] = useRecoilState(
    sliderName === 'jobTitle' ? jobTitleSlide : isPaneOpen,
    sliderName === 'runNow' ? runNowSlide : isPaneOpen
  )
  return (
    <div>
      <SlidingPane
        className={`slidingPane-slider ${className}`}
        overlayClassName="some-custom-overlay-class"
        isOpen={panStatus}
        // onRequestClose={() => {
        //   if (!panStatus) {
        //     setPanStatus(false) // Ensure pane closes when triggered externally
        //   }
        // }}
      >
        <div class="slider-component">{SliderComponent}</div>
      </SlidingPane>
    </div>
  )
}
export default Slider
