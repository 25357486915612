import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
})

const imgloader = atom({
  key: 'imgloader',
  default: false,
})

const loader = atom({
  key: 'loader',
  default: false,
})
const getPersistedState = (key, defaultValue) => {
  const savedValue = sessionStorage.getItem(key)
  return savedValue ? JSON.parse(savedValue) : defaultValue
}

const careerLoadedCount = atom({
  key: 'careerLoadedCount',
  default: getPersistedState('careerLoadedCount', 0),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        sessionStorage.setItem('careerLoadedCount', JSON.stringify(newValue))
      })
    },
  ],
})

const visitedPages = atom({
  key: 'visitedPages',
  default: [],
})
const popupLoader = atom({
  key: 'popupLoader',
  default: false,
})

const redirectApiUrl = atom({
  key: 'redirectApiUrl',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const funcAppApiUrl = atom({
  key: 'funcAppApiUrl',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const blobImageUrl = atom({
  key: 'blobImageUrl',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const blobImageUrl2 = atom({
  key: 'blobImageUrl2',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const getErrorCode = atom({
  key: 'getErrorCode',
  default: null,
})

const getCarrerForm = atom({
  key: 'getCarrerForm',
  default: [
    {
      fullName: '',
      email: '',
      phone: '',
      file: null,
      checked: false,
    },
  ],
})

const toastMessage = atom({
  key: 'ToastMessage',
  default: null,
})

const modalPopup = atom({
  key: 'modalPopup',
  default: null,
})

const Secretkey = atom({
  key: 'Secretkey',
  default: null,
})
const dialogPopUp = atom({
  key: 'dialogPopUp',
  default: false,
})
const getUserId = atom({
  key: 'userId',
  default: null,
})
const rolesPermissionMenus = atom({
  key: 'rolesPermissionMenus',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const userInstance = atom({
  key: 'userInstance',
  default: null,
})
const isPaneOpen = atom({
  key: 'panStatus',
  default: false,
})
const jobTitleSlide = atom({
  key: 'jobTitleSlide',
  default: false,
})
const runNowSlide = atom({
  key: 'runNowSlide',
  default: false,
})
const bearer = atom({
  key: 'bearer',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const signInClicked = atom({
  key: 'signInClicked',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
const userAccessAtom = atom({
  key: 'userAccessAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
export {
  redirectApiUrl,
  getErrorCode,
  popupLoader,
  toastMessage,
  modalPopup,
  Secretkey,
  dialogPopUp,
  getUserId,
  rolesPermissionMenus,
  userInstance,
  isPaneOpen,
  jobTitleSlide,
  runNowSlide,
  bearer,
  getCarrerForm,
  signInClicked,
  userAccessAtom,
  visitedPages,
  imgloader,
  loader,
  careerLoadedCount,
  funcAppApiUrl,
  blobImageUrl,
  blobImageUrl2,
}
