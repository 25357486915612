import { createTheme } from '@mui/material/styles'

import { keyframes } from '@emotion/react'

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

// Create the theme
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '3.75rem',
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.17,
    },
    h4: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.24,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.33,
    },
    h6: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.15px',
    },
    body1: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: 1.43,
      letterSpacing: '0.17px',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.1px',
    },
    caption: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1.66,
      letterSpacing: '0.4px',
    },
    alerttitle: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      letterSpacing: '0.15px',
    },
    button: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '0.938rem', // Define different sizes based on button variant if needed
      fontWeight: 500,
      lineHeight: 1.73,
      letterSpacing: '0.46px',
    },
  },
  palette: {
    primary: {
      main: '#1F3C88',
      text: '#162B48DE',
      dark: '#002737',
    },
    secondary: {
      main: '#123F4C',
      dark: '#123F4C',
      text: '#8E9092',
      hover: '#0277BD0A',
    },
  },

  components: {
    // Customizing the MuiTextField globally
    MuiTextField: {
      styleOverrides: {
        root: {
          // Default input styles here
        },
      },
    },
    // Customizing the error styles for the TextField
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red', // Custom error border color
            },
          },
        },
      },
    },
    // Customizing the error styles for FormHelperText (helperText)
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: 'red', // Change the color to red for error text
            marginLeft: 0, // Align error text to the left (no indentation)
            fontSize: '0.875rem', // Adjust font size
            marginTop: '4px', // Add margin-top for spacing from the input
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'rgb(206, 212, 218)', // Default border color
          },
          '&:hover fieldset': {
            borderColor: 'rgb(206, 212, 218)', // Remove hover border color
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent', // Keep border transparent when focused
          },
          borderRadius: 8, // Adding border-radius globally
        },
      },
    },
  },
})

export default theme
