import React, { useEffect, useLayoutEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, HashRouter, Redirect } from 'react-router-dom'
import RoutesFile from '../views/routes/Routes'
import ErrorBoundary from '../views/components/custom/errorBoundary/ErrorBoundary'
import '../app/App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  blobImageUrl,
  blobImageUrl2,
  funcAppApiUrl,
  redirectApiUrl,
  signInClicked,
  toastMessage,
  userAccessAtom,
} from '../views/components/recoil/atoms/atoms'
import DynamicTittle from '../views/components/base/dynamictittle/DynamicTittle'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'
import Jobs from '../views/pages/Jobs/Jobs/Jobs'
import JobsRoutes from '../views/routes/JobsRoutes'
import useAPIEndpoints from '../models/api/apiEndpoints'
import fetchSelector from '../views/components/recoil/selectors/selectors'

function App({ appConfig }) {
  const [blobImage, setblobImage] = useRecoilState(blobImageUrl)
  const [blobImage2, setblobImage2] = useRecoilState(blobImageUrl2)
  const [baseAPIURL, setBaseAPIURL] = useRecoilState(redirectApiUrl)
  const [funAPIURL, setFunAPIURL] = useRecoilState(funcAppApiUrl)
  const { accounts } = useMsal() // Get the list of authenticated accounts
  const APIEndPoints = useAPIEndpoints()
  const getData = useRecoilValue(fetchSelector)
  const VerifyUser = { EmailAddress: accounts[0]?.username }
  const [userId, setUserId] = useState()
  const [userAccess, setUserAccess] = useRecoilState(userAccessAtom)
  const [clickedSignInBTN, setClickedSignInBTN] = useRecoilState(signInClicked)

  useEffect(() => {
    if (appConfig?.VariableSettings?.CoreDataUIURL) {
      setBaseAPIURL(appConfig.VariableSettings.CoreDataUIURL)
      setFunAPIURL(appConfig.VariableSettings.FuncAppURL)
      setblobImage(appConfig.VariableSettings.BlobURL)
      setblobImage2(appConfig.VariableSettings.BlobURL2)
    }
  }, [appConfig, setBaseAPIURL, setFunAPIURL, setblobImage, setblobImage2])

  useEffect(() => {
    getUserAccess()
  }, [accounts])

  const getUserAccess = () => {
    accounts.length > 0
      ? setUserAccess('haveAccess')
      : clickedSignInBTN
      ? setUserAccess('fromLogout')
      : setUserAccess('accessDenied')
  }

  useLayoutEffect(() => {
    const bodyStyles = document.body.style
    bodyStyles.setProperty('--footerBackgroundColor', '#152B48')
    bodyStyles.setProperty('--fontfamily', 'Roboto')
    bodyStyles.setProperty('--fontWeight', '400')
    bodyStyles.setProperty('--footerOpacity', '0.6')
    bodyStyles.setProperty('--footertextcolor', '#DFDFDF')
    bodyStyles.setProperty('--Secondary', '#FFFFFF')
    bodyStyles.setProperty('--Primary', '#1F3C88')
    bodyStyles.setProperty('--Default', '#738093')
    bodyStyles.setProperty('--textSecondary', '#738091')
    bodyStyles.setProperty('--inputBorderOutline', '#162B483B')
    bodyStyles.setProperty('--MarstonBase', '#162B4B')

    // Set elevation effect variables
    bodyStyles.setProperty(
      '--elevation--1',
      '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--2',
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--3',
      '0px 1px 8px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--4',
      '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--5',
      '0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--6',
      '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--7',
      '0px 2px 16px rgba(0, 0, 0, 0.12), 0px 7px 10px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--8',
      '0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 5px 5px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--9',
      '0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 5px 6px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--10',
      '0px 4px 18px rgba(0, 0, 0, 0.12), 0px 10px 14px rgba(0, 0, 0, 0.14), 0px 6px 6px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--11',
      '0px 4px 20px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.14), 0px 6px 7px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--12',
      '0px 5px 22px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 7px 8px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--13',
      '0px 5px 24px rgba(0, 0, 0, 0.12), 0px 13px 19px rgba(0, 0, 0, 0.14), 0px 7px 8px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--14',
      '0px 5px 26px rgba(0, 0, 0, 0.12), 0px 14px 21px rgba(0, 0, 0, 0.14), 0px 7px 9px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--15',
      '0px 6px 28px rgba(0, 0, 0, 0.12), 0px 15px 22px rgba(0, 0, 0, 0.14), 0px 8px 9px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--16',
      '0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 8px 10px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--17',
      '0px 6px 32px rgba(0, 0, 0, 0.12), 0px 17px 26px rgba(0, 0, 0, 0.14), 0px 8px 11px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--18',
      '0px 7px 34px rgba(0, 0, 0, 0.12), 0px 18px 28px rgba(0, 0, 0, 0.14), 0px 9px 11px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--19',
      '0px 7px 36px rgba(0, 0, 0, 0.12), 0px 19px 29px rgba(0, 0, 0, 0.14), 0px 9px 12px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--20',
      '0px 8px 38px rgba(0, 0, 0, 0.12), 0px 20px 31px rgba(0, 0, 0, 0.14), 0px 10px 13px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--21',
      '0px 8px 40px rgba(0, 0, 0, 0.12), 0px 21px 33px rgba(0, 0, 0, 0.14), 0px 10px 13px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--22',
      '0px 8px 42px rgba(0, 0, 0, 0.12), 0px 22px 35px rgba(0, 0, 0, 0.14), 0px 10px 14px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--23',
      '0px 9px 44px rgba(0, 0, 0, 0.12), 0px 23px 36px rgba(0, 0, 0, 0.14), 0px 11px 14px rgba(0, 0, 0, 0.2)'
    )
    bodyStyles.setProperty(
      '--elevation--24',
      '0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)'
    )
  }, [])

  return (
    <ErrorBoundary>
      <HashRouter>
        <DynamicTittle />
        {/* If user is authenticated, redirect them to Jobs */}
        {clickedSignInBTN === 'fromLogout' && userAccess === 'fromLogout' ? (
          <RoutesFile />
        ) : clickedSignInBTN === 'fromLogout' &&
          (!userAccess === 'fromLogout' || !userAccess === 'accessDenied') ? (
          <RoutesFile />
        ) : userAccess === 'accessDenied' && clickedSignInBTN ? (
          <div></div>
        ) : userAccess === 'haveAccess' ? (
          <div>
            <JobsRoutes />
          </div>
        ) : (
          (!userAccess || userAccess === 'accessDenied') &&
          !clickedSignInBTN && <RoutesFile />
        )}
      </HashRouter>
    </ErrorBoundary>
  )
}

export default App
