// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-text-editor {
  min-height: 170px; /* Set a minimum height */
  max-height: 400px; /* Prevent excessive height */
  overflow-y: auto; /* Enable scrolling when content exceeds max height */
  border: 2px solid #162b483b;

  border-radius: 8px;
  background: #fff;
}

.rich-text-editor.editor-error {
  border: 2px solid #d32f2f;
}
.rich-text-editor.not-editor-error {
  border: 2px solid #162b483b;
}

.editor-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Adds space between label and editor */
}

.editor-label {
  font-size: 14px;
  font-weight: 400;
  color: #162b483b;
}

.rich-text-editor.Addresss {
  min-height: 140px;
  max-height: 170px;
}
.rich-text-editor.JobDescription {
  min-height: 140px;
  max-height: 140px;
}

.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9,
.RichTextEditor__editor___1QqIU pre {
  margin: 0px 0 !important; /* Reduce excessive spacing */
  padding-bottom: 0 !important;
  line-height: 1.2 !important;
}

p {
  line-height: 2;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/custom/texteditor/GlobalEditor.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,yBAAyB;EAC5C,iBAAiB,EAAE,6BAA6B;EAChD,gBAAgB,EAAE,qDAAqD;EACvE,2BAA2B;;EAE3B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ,EAAE,wCAAwC;AACpD;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;;EAEE,wBAAwB,EAAE,6BAA6B;EACvD,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".rich-text-editor {\n  min-height: 170px; /* Set a minimum height */\n  max-height: 400px; /* Prevent excessive height */\n  overflow-y: auto; /* Enable scrolling when content exceeds max height */\n  border: 2px solid #162b483b;\n\n  border-radius: 8px;\n  background: #fff;\n}\n\n.rich-text-editor.editor-error {\n  border: 2px solid #d32f2f;\n}\n.rich-text-editor.not-editor-error {\n  border: 2px solid #162b483b;\n}\n\n.editor-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px; /* Adds space between label and editor */\n}\n\n.editor-label {\n  font-size: 14px;\n  font-weight: 400;\n  color: #162b483b;\n}\n\n.rich-text-editor.Addresss {\n  min-height: 140px;\n  max-height: 170px;\n}\n.rich-text-editor.JobDescription {\n  min-height: 140px;\n  max-height: 140px;\n}\n\n.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9,\n.RichTextEditor__editor___1QqIU pre {\n  margin: 0px 0 !important; /* Reduce excessive spacing */\n  padding-bottom: 0 !important;\n  line-height: 1.2 !important;\n}\n\np {\n  line-height: 2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
