// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_type_locationsave {
  color: #2c95b5;
  font-weight: 500;
  background-color: #005c84;
  color: #fff;
  border-radius: 8px;
}

.button_type_cancel {
  color: #2c95b5;
  border: 1px solid #0277bd80;
  color: #2c95b5;
  border-radius: 8px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/confirmationDialog/ConfirmationDialog.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".button_type_locationsave {\n  color: #2c95b5;\n  font-weight: 500;\n  background-color: #005c84;\n  color: #fff;\n  border-radius: 8px;\n}\n\n.button_type_cancel {\n  color: #2c95b5;\n  border: 1px solid #0277bd80;\n  color: #2c95b5;\n  border-radius: 8px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
