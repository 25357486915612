import React, { useEffect, useState } from 'react'
import './Jobs.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  blobImageUrl2,
  dialogPopUp,
  getUserId,
  loader,
  signInClicked,
  toastMessage,
  userAccessAtom,
} from '../../../components/recoil/atoms/atoms'
import fulldelete from '../../../../assets/image/fulldelete.jpg'
import JobIcon from '../../../../assets/image/JobsIcon.svg'
import NewResumes from '../../../../assets/image/NewResumes.svg'
import demography from '../../../../assets/image/demography.svg'
import visibility from '../../../../assets/image/visibility.svg'
import EditIcon from '../../../../assets/image/edit_square.svg'
import Downloadicon from '../../../../assets/image/Downloadicon.svg'
import DeleteIcon from '../../../../assets/image/Grid-deleteicon.svg'
import TableDataGrid from '../../../components/base/grid/Grid'
import ButtonComponent from '../../../components/base/button/Button'
import { useNavigate, useLocation } from 'react-router-dom'
import CloseButton from '../../../../assets/image/Close_btn.svg'
import accountCircle from '../../../../assets/image/account_circle.svg'
import CryptoJS from 'crypto-js'
import labProfile from '../../../../assets/image/lab_profile.svg'
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  Divider,
  Box,
} from '@mui/material'
import '../../../pages/SEOTags/AddSEOTags.css'
import Popup from '../../../components/base/popup/Popup'
import AutoCompleteComponent from '../../../components/base/autoComplete/AutoComplete'
import ToastMessage from '../../../components/base/toastMessage/ToastMessage'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'
import { useMsal } from '@azure/msal-react'
import { signOut } from '../../../../app/authService'
import AdminHeader from '../../../components/custom/adminHeader/AdminHeader'
import { Helmet } from 'react-helmet'

const Jobs = () => {
  const BlobURL2 = useRecoilValue(blobImageUrl2)
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const [toastShown, setToastShown] = useState({
    Deletesuccess: false,
  })
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [selectedResumeRowIds, setSelectedResumeRowIds] = useState([])
  const [bulkJobId, setBulkJobId] = useState(null)
  const handleSelectionChange = (newSelection) => {
    setSelectedRowIds(newSelection)
  }
  const handleResumeRowSelection = (newSelection) => {
    setSelectedResumeRowIds(newSelection)
  }
  const handleTotalRowSelection = (newSelection) => {}
  const location = useLocation()
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [jobDeleteId, setJobDeleteId] = useState(null)
  const jobText = location.state?.jobText
  const navigate = useNavigate()
  const [dashboardDetails, setDashboardDetails] = useState({
    newCount: '',
  })
  const [rows, setRows] = useState([])
  const [resumeData, setResumeData] = useState([])
  const [totalResumeData, setTotalResumeData] = useState([])
  const [boxText, setBoxText] = useState()
  const [loading, setLoading] = useRecoilState(loader)
  const [clickedIndex, setClickedIndex] = useState('Jobs')
  const [clickedIndexPopUp, setClickedIndexPopUp] = useState(false)
  const [open, setOpen] = useRecoilState(dialogPopUp) //popup
  const [deleteopen, setDeleteOpen] = useRecoilState(dialogPopUp)
  const getData = useRecoilValue(fetchSelector)
  const APIEndPoints = useAPIEndpoints()
  const [rowId, seRowId] = useState()
  const [tempData, setTempData] = useState(null)
  const userId = useRecoilValue(getUserId)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [newCount, setNewCount] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState({ Id: '', Name: '' })
  const resumeDataNew = resumeData.filter((audit) => audit?.status === 'New')
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const currentYear = new Date().getFullYear()

  const pageList = [
    { Id: '1', Name: 'New' },
    { Id: '2', Name: 'Hired' },
    { Id: '3', Name: 'On Hold' },
    { Id: '4', Name: 'Rejected' },
    { Id: '5', Name: 'In-Progress' },
  ]
  const accessToken = sessionStorage.getItem('accessToken')
  useEffect(() => {
    jobText === 'New Resumes' ? setClickedIndex('Received Resumes') : null
  }, jobText)
  function popupContent(rowId) {
    const filteredRows = rows?.filter((data) => data.id === rowId)
    return (
      <div>
        <div className="DialogContainerHeader">
          <DialogTitle>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <div className="ApplicantDetailTitleHeaderMain">
                <div>
                  {' '}
                  <img src={accountCircle} alt="Download"></img>
                </div>
                <div className="ApplicantDetailHead">
                  <div className="ApplicantDetailText">Job Details</div>
                </div>
              </div>
              <div>
                {' '}
                <img
                  src={CloseButton}
                  alt="Download"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpen(false)
                    setClickedIndexPopUp(false)
                  }}
                ></img>
              </div>
            </Stack>
          </DialogTitle>
          {filteredRows?.map((data) => (
            <DialogContent className="mainDialogBoxHeader">
              <DialogContentText id="alert-dialog-slide-description">
                <div className="applicantContainerHeader">
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Title :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.role}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Role :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.title}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment skill">
                    <Typography className="roleParent-container-text">
                      Skill :
                    </Typography>
                    <div className="roleParent-container-text skill">
                      <span title={data?.skill}>{data?.skill}</span>
                    </div>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Created Date :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.createdDate}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Status :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.status}
                    </Typography>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          ))}
        </div>{' '}
      </div>
    )
  }
  function resumespopupContent(rowId) {
    const resumeFilteredRows = resumeData?.filter((data) => data.id === rowId)

    const handleUpdateResume = async (resumeId) => {
      await getData(
        APIEndPoints.UpdateResumeStatus.method,
        ` ${APIEndPoints.UpdateResumeStatus.url}`,
        {
          RowKey: rowId,
          Status: selectedStatus?.Name,
        }
      ).then((resp) => {
        setDataUpdated(!dataUpdated)
        setOpen(false)
      })
    }
    const handleSelectChange = (e, params) => {
      setSelectedStatus(params)
    }
    const date = resumeFilteredRows?.map((data) => data?.submitedOn)
    const formattedDate = new Date(date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // 12-hour format (AM/PM)
    })
    return (
      <div>
        <Helmet>
          <title>Jobs</title>
          <meta name="description" content="This is Page built with React!" />
          <meta property="og:title" content="Jobs" />
          <meta
            property="og:description"
            content="This is Page built with React!"
          />
          <meta
            property="og:image"
            content="https://lvsitecdn.azureedge.net/Images/logicvalley-headerlogo.svg"
          />
          <main>
            <h1>Logicvalley-Jobs</h1>
          </main>
        </Helmet>
        <div className="DialogContainerHeader">
          <DialogTitle>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <div className="ApplicantDetailTitleHeaderMain">
                <div>
                  {' '}
                  <img src={accountCircle} alt="Download"></img>
                </div>
                <div className="ApplicantDetailHead">
                  <div className="ApplicantDetailText">Applicant Details</div>
                </div>
              </div>
              <div>
                {' '}
                <img
                  src={CloseButton}
                  alt="Download"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(false)}
                ></img>
              </div>
            </Stack>
          </DialogTitle>
          {resumeFilteredRows?.map((data) => (
            <DialogContent className="mainDialogBoxHeader">
              <DialogContentText id="alert-dialog-slide-description">
                <div className="applicantContainerHeader">
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Name :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.name}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Email Address :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.emailAddress}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Phone Number :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      +{data?.phoneNumber}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Role :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.role}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Submitted On:
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {formattedDate}
                    </Typography>
                  </div>
                  <div
                    className="downLoadContainer"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      const link = document.createElement('a')
                      link.href = `${BlobURL2}${data?.fileName}`
                      link.download = data?.fileName
                      link.click()
                    }}
                  >
                    <div>
                      <img src={labProfile} alt="labProfile" />{' '}
                    </div>
                    <div className="downLoadFile">
                      <div className="downLoadName">Download Resume</div>
                      <div className="downloadFileName">
                        <span title={data?.fileName}> {data?.fileName}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className="divider" />
                <AutoCompleteComponent
                  initialValue={{
                    Name: selectedStatus?.Name || data?.status,
                  }}
                  value={selectedStatus ? selectedStatus.Id : ''}
                  selectChangeHandler={handleSelectChange} // Update both Id and Name
                  options={pageList}
                  placeholder="Select status"
                  label="Status"
                />
              </DialogContentText>
              <div class="submit-btn">
                <ButtonComponent
                  text="SUBMIT & CLOSE"
                  className="jobsSave"
                  variant="contained"
                  onClick={() => handleUpdateResume(data)}
                />
              </div>
            </DialogContent>
          ))}
        </div>{' '}
      </div>
    )
  }

  const totalResumesPopupContent = (rowId) => {
    const handleUpdateResume = async (resumeId) => {
      await getData(
        APIEndPoints.UpdateResumeStatus.method,
        ` ${APIEndPoints.UpdateResumeStatus.url}`,
        {
          RowKey: rowId,
          Status: selectedStatus?.Name,
        }
      ).then((resp) => {
        setDataUpdated(!dataUpdated)
        setOpen(false)
      })
    }

    const date = resumeData
      ?.filter((data) => data?.id === rowId)
      .map((data) => data?.submitedOn)
    const formattedDate = new Date(date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // 12-hour format (AM/PM)
    })
    const handleSelectChange = (e, params) => {
      setSelectedStatus(params)
    }
    const resumeFilteredRows = resumeData?.filter((data) => data.id === rowId)

    return (
      <div>
        <div className="DialogContainerHeader">
          <DialogTitle>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <div className="ApplicantDetailTitleHeaderMain">
                <div>
                  {' '}
                  <img src={accountCircle} alt="Download"></img>
                </div>
                <div className="ApplicantDetailHead">
                  <div className="ApplicantDetailText">Applicant Details</div>
                </div>
              </div>
              <div>
                {' '}
                <img
                  src={CloseButton}
                  alt="Download"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(false)}
                ></img>
              </div>
            </Stack>
          </DialogTitle>
          {resumeFilteredRows?.map((data) => (
            <DialogContent className="mainDialogBoxHeader">
              <DialogContentText id="alert-dialog-slide-description">
                <div className="applicantContainerHeader">
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Name :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.name}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Email Address :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.emailAddress}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Phone Number :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      +{data?.phoneNumber}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Role :{data?.file}
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {data?.role}
                    </Typography>
                  </div>
                  <div className="roleTextAlignment">
                    <Typography className="roleParent-container-text">
                      Submitted On :
                    </Typography>
                    <Typography className="roleParent-container-text">
                      {formattedDate}
                    </Typography>
                  </div>
                  <div
                    className="downLoadContainer"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.preventDefault()
                      const link = document.createElement('a')
                      link.href = `${BlobURL2}${data?.fileName}`
                      link.download = data?.fileName
                      link.click()
                    }}
                  >
                    <div>
                      <img src={labProfile} alt="labProfile" />{' '}
                    </div>
                    <div className="downLoadFile">
                      <div className="downLoadName">Download Resume</div>
                      <div className="downloadFileName">
                        <span title={data?.fileName}>{data?.fileName}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className="divider" />
                <AutoCompleteComponent
                  initialValue={{
                    Name: selectedStatus?.Name || data?.status,
                  }}
                  value={selectedStatus ? selectedStatus.Id : ''}
                  selectChangeHandler={handleSelectChange} // Update both Id and Name
                  options={pageList}
                  placeholder="Select status"
                  label="Status"
                />
              </DialogContentText>
              <div class="submit-btn">
                <ButtonComponent
                  text="SUBMIT & CLOSE"
                  className="jobsSave"
                  variant="contained"
                  onClick={() => handleUpdateResume(data)}
                />
              </div>
            </DialogContent>
          ))}
        </div>{' '}
      </div>
    )
  }

  // JobDetail geting
  const getJobDetailData = async () => {
    setLoading(true)
    await getData(
      APIEndPoints.GetJobDetails.method,
      APIEndPoints.GetJobDetails.url
    )
      .then((resp) => {
        setLoading(false)
        setRows(
          resp.data
            .filter((data) => data?.isActive === true)
            ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((audit, index) => {
              // Format the createdDate
              const formattedDate = new Date(audit?.timestamp).toLocaleString(
                'en-GB',
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true, // 12-hour format (AM/PM)
                }
              )
              return {
                id: audit?.rowKey,
                role: audit?.jobTitle,
                title: audit?.jobRole,
                status:
                  audit?.status === 'Publish' ? 'Published' : audit?.status,
                rowKey: audit?.rowKey,
                skill: audit?.skills,
                createdDate: formattedDate,
              }
            })
        )
      })
      .catch((error) => {
        setLoading(false)

        throw error
      })
  }

  const getResumeDataDetail = async () => {
    setLoading(true)
    await getData(
      APIEndPoints.GetResumeDetails.method,
      APIEndPoints.GetResumeDetails.url
    )
      .then((resp) => {
        setLoading(false)
        boxText === 'Jobs'
          ? setResumeData(
              resp.data
                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                .map((audit, index) => ({
                  id: audit?.rowKey,
                  name: audit?.name,
                  role: audit?.jobRole,
                  emailAddress: audit?.emailAddress,
                  phoneNumber: audit?.phoneNumber,
                  status: audit?.status,
                  createdDate: audit?.timestamp,
                  fileName: audit?.fileName,
                }))
            )
          : setResumeData(
              resp.data
                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                .map((audit, index) => ({
                  id: audit?.rowKey,
                  name: audit?.fullName,
                  role: audit?.jobRole,
                  emailAddress: audit?.emailAddress,
                  phoneNumber: audit?.phoneNumber,
                  status: audit?.status,
                  fileName: audit?.name,
                  fileNameLink: audit?.fileName,
                  resumeId: audit?.resumeId,
                  submitedOn: audit?.timestamp,
                  fileName: audit?.fileName,
                }))
            )
        setTotalResumeData(
          resp.data.map((audit, index) => ({
            id: audit?.rowKey,
            name: audit?.fullName,
            role: audit?.jobRole,
            emailAddress: audit?.emailAddress,
            phoneNumber: audit?.phoneNumber,
            status: audit?.status,
            fileName: audit?.fileName,
            fileNameLink: audit?.fileNameLink,
            resumeId: audit?.resumeId,
            submitedOn: audit?.timestamp,
          }))
        )
        setDashboardDetails({
          newCount: resp?.data?.data?.filter(
            (item) => item.resumeStatus === 'New'
          ).length,
        })
        if (resp) {
          // Extract filenames from documentLink in recentApplications
          resp?.data?.data?.forEach((data) => {
            const fileName = new URL(data?.fileNameLink).pathname
              .split('/')
              .pop()
            setTempData(fileName)
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }
  useEffect(() => {
    getResumeDataDetail()
    getJobDetailData()
  }, [dataUpdated])

  const handleRowClick = (event, row) => {
    event.stopPropagation()
    setClickedIndex('Jobs')
    setClickedIndexPopUp(true)
    seRowId(row.id)
    setOpen(true)
  }
  const handleReceivedResumeRowClick = (event, row) => {
    setOpen(true)
    event.stopPropagation()
    setClickedIndex('Received Resumes')
    setSelectedStatus(selectedStatus?.Id)
    seRowId(row.id)
  }
  const handleTotalResumeRowClick = (event, row) => {
    setOpen(true)
    event.stopPropagation()
    setClickedIndex('Total Resumes')
    setSelectedStatus(selectedStatus?.Id)
    seRowId(row.id)
  }

  const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(String(data), 'LVWebsite').toString()
    return encodeURIComponent(
      encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '') // URL-safe
    ) // Convert to Base64 & encode safely
  }

  const handleEdit = async (roleId) => {
    navigate(`/Editjob/${encryptData(roleId)}`)
  }

  const handleDelete = async (event, row) => {
    setDeleteOpen(true)
    setDeletePopUp(true)
    setJobDeleteId(row.rowKey)
  }
  const handleJobDelete = async (jobDeleteId) => {
    await getData(
      APIEndPoints.deleteJobRole.method,
      APIEndPoints.deleteJobRole.url,
      [
        {
          RowKey: jobDeleteId?.jobDeleteId,
          IsActive: false,
        },
      ]
    ).then((resp) => {
      setDataUpdated(!dataUpdated)
      setJobDeleteId(null)
      setDeleteOpen(false)
      setDeletePopUp(false)
    })
  }
  const handleJobBulkDelete = async (BulkDeletedId) => {
    const BulkDeletedAll = selectedRowIds.map((id) => {
      return { RowKey: id.toString(), IsActive: false }
    })
    await getData(
      APIEndPoints.deleteJobRole.method,
      APIEndPoints.deleteJobRole.url,
      BulkDeletedAll
    ).then((resp) => {
      setDataUpdated(!dataUpdated)
      setJobDeleteId(null)
      setDeleteOpen(false)
      setDeletePopUp(false)
      setSelectedRowIds([])
    })
  }

  const jobData = [
    { jobNumber: rows?.length, jobText: 'Jobs' },
    {
      jobNumber: resumeDataNew?.length,
      jobText: 'Received Resumes',
    },
    { jobNumber: resumeData?.length, jobText: 'Total Resumes' },
  ]
  const iconMap = {
    Jobs: JobIcon,
    'Received Resumes': NewResumes,
    'Total Resumes': demography,
  }
  const handleClickNavigate = () => {
    navigate('/CreateNewjob')
  }

  const JobBox = ({ jobNumber, jobText, onClick, clicked, index }) => {
    const iconSrc = iconMap[jobText] || JobIcon
    const handleClick = () => {
      if (onClick) {
        onClick(index, jobText)
      } else {
        console.log(`Job box clicked at index ${index}: ${jobText}`)
      }
    }

    return (
      <div
        className={`${
          clicked === jobText
            ? `Not-outer-box ${clicked}`
            : 'outer-boxContainer'
        } `}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        <div className="job-text">{jobText}</div>
        <div className="JobsContentContainer">
          <div className="job-number-text">
            {jobText === 'Received Resumes' ? (
              <div className="totalName">New</div>
            ) : (
              <div className="totalName">Total</div>
            )}
            <div>{jobNumber < 9 ? `0${jobNumber}` : jobNumber}</div>
          </div>
          <img src={iconSrc} alt={jobText} className="job-icon" />
        </div>
      </div>
    )
  }

  const columns = [
    {
      field: 'role',
      headerName: 'Title',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'skill',
      headerName: 'Skill',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date & Time',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'mui-header',
      flex: 1,
      sortable: false,
      editable: true,
      disableExport: true,
      filterable: false,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const Disabled = selectedRowIds.includes(row.rowKey)
        return (
          <div className="jobActionContainer">
            <div>
              <img
                src={visibility}
                style={{
                  cursor: Disabled ? 'not-allowed' : 'pointer',
                  opacity: Disabled ? 0.5 : 1,
                  pointerEvents: Disabled ? 'none' : 'auto',
                }}
                onClick={(event) => {
                  if (!Disabled) {
                    handleRowClick(event, row)
                  }
                }}
                alt="visibility"
              />
            </div>
            <div>
              <img
                src={EditIcon}
                onClick={(event) => {
                  if (!Disabled) {
                    handleEdit(row.rowKey)
                  }
                }}
                style={{
                  cursor: Disabled ? 'not-allowed' : 'pointer',
                  opacity: Disabled ? 0.5 : 1,
                  pointerEvents: Disabled ? 'none' : 'auto',
                }}
                alt="editIcon"
              />
            </div>
            <div>
              <img
                src={DeleteIcon}
                onClick={(event) => {
                  if (!Disabled) {
                    event.stopPropagation()
                    handleDelete(event, row)
                  }
                }}
                style={{
                  cursor: Disabled ? 'not-allowed' : 'pointer',
                  opacity: Disabled ? 0.5 : 1,
                  pointerEvents: Disabled ? 'none' : 'auto',
                }}
                alt="deleteIcon"
              />
            </div>
          </div>
        )
      },
    },
  ]
  const resumesColumns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'role',
      headerName: 'Role',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      editable: true,
      disableExport: true,
      filterable: false,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      headerClassName: 'mui-header',
      flex: 1,
      renderCell: ({ row }) => {
        const Disabled = selectedResumeRowIds.includes(row.id)

        return (
          <div className="jobActionContainer">
            <img
              src={Downloadicon}
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                e.preventDefault()
                const link = document.createElement('a')
                link.href = `${BlobURL2}${row?.fileName}`
                link.download = row?.fileName
                link.click()
              }}
              alt="downloadIcon"
            />
            <img
              src={visibility}
              style={{
                cursor: 'pointer',
              }}
              onClick={(event) => {
                handleReceivedResumeRowClick(event, row)
              }}
              alt="visibility"
            />
          </div>
        )
      },
    },
  ]
  const totalResumesColumns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'role',
      headerName: 'Role',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'mui-header',
      flex: 1,
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'mui-header',
      flex: 1,
      sortable: false,
      editable: true,
      disableExport: true,
      filterable: false,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div className="jobActionContainer">
            <img
              src={Downloadicon}
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                e.preventDefault()
                const link = document.createElement('a')
                link.href = `${BlobURL2}${row?.fileName}`
                link.download = row?.fileName
                link.click()
              }}
              alt="downloadIcon"
            />
            <img
              src={visibility}
              style={{
                cursor: 'pointer',
              }}
              onClick={(event) => handleTotalResumeRowClick(event, row)}
              alt="visibility"
            />
          </div>
        )
      },
    },
  ]

  const handleJobClick = (jobText, index) => {
    setBoxText(jobText)
    setClickedIndex(index)
  }

  const DeletePopupContent = (jobDeleteId, BulkDeletedId) => {
    return (
      <div class="mainpop-seo">
        <div class="mainpop-header">
          <div class="main-popsubheader">
            Are you sure you want to delete this Job?
          </div>
          <div class="main-popheaderContent">
            This action is Permanent and cannot be undone.
          </div>
        </div>
        <div class="seo-btn-pop">
          <div class="cancel-btn">
            <ButtonComponent
              text="No"
              className="seocancel"
              onClick={() => {
                setDeleteOpen(false)
                setDeletePopUp(false)
              }}
            />
          </div>
          <div class="submit-btn">
            <ButtonComponent
              text="Yes"
              className="seoYes"
              onClick={() => {
                if (jobDeleteId?.jobDeleteId) {
                  handleJobDelete(jobDeleteId)
                } else {
                  handleJobBulkDelete(jobDeleteId.BulkDeletedId)
                }
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      <AdminHeader></AdminHeader>
      <div className="jobsMainContainer">
        {deletePopUp && deleteopen && (
          <Popup
            className="Delete-confirmation_popup"
            popContent={
              <DeletePopupContent
                jobDeleteId={jobDeleteId}
                BulkDeletedId={selectedRowIds}
              />
            }
          />
        )}
        {toastOpen && toastShown.Deletesuccess && (
          <ToastMessage
            statusMassage="SUCCESS !"
            contentMessage="Job Details Deleted successfully"
          />
        )}
        <div className="jobsCardContainer">
          <div className="jobsCard">
            {jobData.map((job, index) => (
              <JobBox
                className="jobBox"
                key={index}
                jobNumber={job.jobNumber}
                jobText={job.jobText}
                onClick={handleJobClick}
                clicked={clickedIndex}
                index={index}
              />
            ))}
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div class="submit-btn">
              <ButtonComponent
                text="CREATE NEW JOB"
                className="eventSave"
                variant="contained"
                onClick={handleClickNavigate}
              />
            </div>
          </div>
        </div>
        <div className="jobsGridContainer">
          {clickedIndex === 'Received Resumes' && (
            <TableDataGrid
              columnData={resumesColumns}
              rowData={resumeDataNew}
              onRowSelectionChange={handleResumeRowSelection}
            />
          )}{' '}
          {clickedIndex === 'Total Resumes' && (
            <TableDataGrid
              columnData={totalResumesColumns}
              rowData={totalResumeData}
              onRowSelectionChange={handleTotalRowSelection}
            />
          )}
          {clickedIndex === 'Jobs' && (
            <div className="jobs-grid">
              {selectedRowIds.length > 1 && (
                <div className="delete-popup">
                  <ButtonComponent
                    image={fulldelete}
                    text={` Delete`}
                    size="medium"
                    onClick={() => {
                      setDeleteOpen(true)
                      setDeletePopUp(true)
                    }}
                  />
                </div>
              )}
              <TableDataGrid
                columnData={columns}
                rowData={rows}
                checkboxSelection
                onRowSelectionChange={handleSelectionChange}
                setSelectedRows={setSelectedRowIds}
                selectedRows={selectedRowIds}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel)
                }}
              />
            </div>
          )}
        </div>
        <div>
          {clickedIndex === 'Received Resumes' ? (
            <Popup popContent={resumespopupContent(rowId)} />
          ) : clickedIndex === 'Total Resumes' ? (
            <Popup popContent={totalResumesPopupContent(rowId)} />
          ) : clickedIndex === 'Jobs' && clickedIndexPopUp === true ? (
            <Popup popContent={popupContent(rowId)} className="jobsPopup" />
          ) : null}
        </div>
        <Box className="captionContainer">
          <Typography
            variant="caption"
            color="#162B4B"
            fontWeight={400}
            className="copyrightContent"
          >
            © {currentYear} LogicValley - part of Marston Holdings
          </Typography>
        </Box>
      </div>
    </div>
  )
}

export default Jobs
