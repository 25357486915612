import axios from 'axios'
import { selector } from 'recoil'
import { trackPromise } from 'react-promise-tracker'
import { bearer, getErrorCode } from '../atoms/atoms'

const fetchSelector = selector({
  key: 'fetchSelector',
  get: ({ get, getCallback }) => {
    const fetchData = async (
      method,
      url,
      postData,
      contentType,
      enableTrackPromise = true
    ) => {
      let errorCodeStatus = null
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': contentType || 'application/json',
          Accept: 'application/json',
        },
      })
      const updateErrorAtom = getCallback(async ({ set }) => {
        set(getErrorCode, errorCodeStatus)
      })
      const getData = async () => {
        const response = await axiosInstance({
          method,
          url,
          data: postData,
        })
          .then((responseData) => responseData)
          .catch((err) => {
            return err
          })
        return response
      }
      if (enableTrackPromise) {
        const response = await trackPromise(getData())
        return response
      }
      const response = getData()
      return response
    }
    return fetchData
  },
})

export default fetchSelector
