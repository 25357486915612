import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import LoadingComponent from '../components/base/loadingcomponent/LoadingComponent'
import CreateNewJobs from '../pages/Jobs/Jobs/CreateNewJobs'
import CreateJobRole from '../pages/Jobs/Jobs/createjobrole/CreateJobRole'
const Jobs = lazy(() => import('../pages/Jobs/Jobs/Jobs'))

function JobsRoutes() {
  return (
    <React.Suspense fallback={<LoadingComponent />}>
      <Routes>
        <Route path="/login" element={<Jobs />} />
        <Route path="/createJobRole" element={<CreateJobRole />} />
        <Route path="/createNewJob" element={<CreateNewJobs />} />
        <Route exact path="/Editjob/:Id" element={<CreateNewJobs />} />
      </Routes>
    </React.Suspense>
  )
}

export default JobsRoutes
