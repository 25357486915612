// src/ga4.js
import ReactGA from 'react-ga4'

export const initGA = () => {
  ReactGA.initialize('G-Q9KXYZRZH8') // Replace with your GA4 Measurement ID
}

export const trackPageview = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path })
}

export const trackEvent = (category, action, label = '') => {
  ReactGA.event({
    category,
    action,
    label,
  })
}
