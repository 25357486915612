import { PublicClientApplication } from '@azure/msal-browser'
console.log('process', process.env.REACT_APP_ENV)

const msalConfig = {
  auth: {
    clientId: '280be665-60a5-406d-a623-d705e2901e3a', // Replace with your Application (client) ID
    authority:
      'https://login.microsoftonline.com/3734172a-e82a-4ac7-a3d3-02949970d5e6', // Replace with your Directory (tenant) ID
    // redirectUri:
    //   'https://proud-wave-0450cc903-preview.westeurope.4.azurestaticapps.net/#/', // Must match the one in Azure portal
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // or "localStorage"
    storeAuthStateInCookie: true, // Helps in some browser issues
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)
