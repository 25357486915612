import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function DynamicTittle() {
  const location = useLocation()

  useEffect(() => {
    const pathToTitleMap = {
      '/': `Home - ${'LogicValley Technologies'}`,
      '/about': `About - ${'LogicValley Technologies'}`,
      '/artificialintelligence': `Artificial Intelligence - ${'LogicValley Technologies'}`,
      '/experiencedesign': `Experience Design - ${'LogicValley Technologies'}`,
      '/productdevelopement': `Product Developement - ${'LogicValley Technologies'}`,
      '/qualityassurance': `Quality Assurance - ${'LogicValley Technologies'}`,
      '/cloudservices': `Cloud Services - ${'LogicValley Technologies'}`,
      '/culture': `Culture - ${'LogicValley Technologies'}`,
      '/career': `Careers - ${'LogicValley Technologies'}`,
      '/contact': `Contact - ${'LogicValley Technologies'}`,
    }

    const title =
      pathToTitleMap[location.pathname] || 'LogicValley Technologies'
    document.title = title
  }, [location])

  return null
}

export default DynamicTittle
