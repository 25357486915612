import React, { useEffect, useState } from 'react'
import './CreateNewJobs.css'
import {
  Button,
  Grid2,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import TextBox from '../../../components/base/textBox/TextField'
import GlobalEditor from '../../../components/custom/texteditor/GlobalEditor'
import AutoCompleteComponent from '../../../components/base/autoComplete/AutoComplete'
import {
  getErrorCode,
  getUserId,
  isPaneOpen,
  loader,
  toastMessage,
} from '../../../components/recoil/atoms/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import useApiEndPoints from '../../../../models/api/apiEndpoints'
import CreateJobRole from './createjobrole/CreateJobRole'
import ReactRTE from 'react-rte'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from '../../../components/base/slidingPane/SlidingPane'
import decryptData from '../../../components/custom/encrypt&decrypt/Decrypt'
import ToastMessage from '../../../components/base/toastMessage/ToastMessage'
import ConfirmationDialog from '../../../components/base/confirmationDialog/ConfirmationDialog'
import { MuiChipsInput } from 'mui-chips-input'
import { Helmet } from 'react-helmet'

const CreateNewJobs = () => {
  const navigate = useNavigate()
  const [rowKey, setRowKey] = useState({})
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobrole: '',
    jobtype: { Id: '', Name: '' },
    locationname: { Id: '', Name: '' },
    jobdescription: ReactRTE.createEmptyValue(),
    skills: [],
    status: 'Publish',
  })
  const [errors, setErrors] = useState({
    jobTitle: '',
    jobtype: '',
    jobrole: '',
    jobdescription: '',
    locationname: '',
    skills: '',
  })
  const [isSaveClick, setisSaveClick] = useState(false)
  const userId = useRecoilValue(getUserId)
  const [locationList, SetlocationList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const getData = useRecoilValue(fetchSelector)
  const APIEndPoints = useApiEndPoints()
  const [panStatus, setPanStatus] = useRecoilState(isPaneOpen)
  const [PaneContent, setPanContent] = useState(false)
  const [statusOptions, setStatusOptions] = useState([
    { status: 'Publish' },
    { status: 'Draft' },
  ])
  const [globalerror, setGlobalError] = useRecoilState(getErrorCode)
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const [toastShown, setToastShown] = useState({
    success: false,
    pageNamealready: false,
    message: '',
  })
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [deleteid, setDeletedid] = useState('')
  const [jobroleTrigger, setjobroleTrigger] = useState(false)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [confirmDialogAction, setConfirmDialogAction] = useState(null)
  const [skillValidationError, setSkillValidationError] = useState(false)
  useEffect(() => {
    getlocationDetails()
  }, [])
  const jobtypeList = [
    { Id: '1', Name: 'Hybrid' },
    { Id: '2', Name: 'Remote' },
    { Id: '3', Name: 'Onsite' },
  ]
  const locationDetail = [
    { Id: '1', Name: 'Coimbatore' },
    { Id: '2', Name: 'Chennai' },
  ]
  const location = useLocation()
  const jobId = location.pathname.split('/').pop()
  const decryptedSeoId = decryptData(jobId)

  useEffect(() => {
    if (decryptedSeoId !== null) {
      getJobDetailsById()
    }
  }, [])

  const getlocationDetails = async () => {
    setLoading(true)
    await getData(
      APIEndPoints.GetJobDetails.method,
      APIEndPoints.GetJobDetails.url
    )
      .then((resp) => {
        if (resp && resp.data) {
          setLoading(false)
          SetlocationList(
            resp?.data?.data?.locationDetails.length > 0
              ? resp.data.data.locationDetails.map(({ id, locationAlias }) => ({
                  Id: id,
                  Name: locationAlias,
                }))
              : []
          )
          const { message, statusCode } = resp.data
          if (statusCode !== 200 && statusCode !== 409) {
            setGlobalError(statusCode)
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setGlobalError(202)
      })
  }

  const getJobDetailsById = async () => {
    setLoading(true)
    await getData(
      APIEndPoints.GetJobDetailsById.method,
      `${APIEndPoints.GetJobDetailsById.url}&rowKey=${decryptedSeoId}`
    )
      .then((resp) => {
        if (resp && resp.data) {
          setLoading(false)
          console.log('data?.skills', resp.data?.skills)

          let data = resp.data
          setRowKey(data?.rowKey)
          setFormData((pre) => ({
            ...pre,
            jobTitle: data?.jobTitle,
            jobdescription: ReactRTE.createValueFromString(
              data?.jobDescription || '',
              'html'
            ),
            skills: data?.skills.split(',').map((skill) => skill.trim()),
            status: data.status,
            jobrole: data.jobRole,
            jobtype: { Id: 1, Name: data.jobType },
            locationname: {
              Id: 1,
              Name: data.location,
            },
          }))
        }
      })
      .catch((err) => {
        setLoading(false)
        setGlobalError(202)
      })
  }

  const handleJob = (e) => {
    const updatedFormData = {
      ...formData,
      jobTitle: e.target.value,
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const handleJobRoleChange = (e, params) => {
    const updatedFormData = {
      ...formData,
      jobrole: e.target.value,
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const handleJobTypeChange = (e, params) => {
    const updatedFormData = {
      ...formData,
      jobtype: { Id: params?.Id, Name: params?.Name },
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const handleLocationName = (e, params) => {
    const updatedFormData = {
      ...formData,
      locationname: { Id: params?.Id, Name: params?.Name },
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const handleTextEditor = (newValue) => {
    const updatedFormData = {
      ...formData,
      jobdescription: newValue,
    }
    setFormData(updatedFormData)
    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const handleChange = (event, newStatus) => {
    if (newStatus !== null) {
      setFormData({
        ...formData,
        status: newStatus,
      })
    }
  }

  const handleChangeChip = (newChips) => {
    const updatedFormData = {
      ...formData,
      skills: newChips,
    }
    setFormData(updatedFormData)

    if (newChips.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        skills: '',
      }))
    }

    if (isSaveClick) {
      setErrors(validateForm(updatedFormData))
    }
  }

  const validateForm = (values) => {
    let errors = {}

    // Job Title Validation
    if (!values.jobTitle) {
      errors.jobTitle = 'Please enter the job title.'
    } else if (values.jobTitle.trim().length < 3) {
      errors.jobTitle = 'Job title must be at least 3 characters.'
    } else if (values.jobTitle.trim().length > 100) {
      errors.jobTitle = 'Job title must not exceed 100 characters.'
    } else if (!/^[A-Za-z\s]+$/.test(values.jobTitle.trim())) {
      errors.jobTitle = 'Job title can only contain letters.'
    }
    if (!values.jobrole) {
      errors.jobrole = 'Please enter the job role.'
    } else if (values.jobrole.trim().length < 3) {
      errors.jobrole = 'Job role must be at least 3 characters.'
    } else if (values.jobrole.trim().length > 100) {
      errors.jobrole = 'Job role must not exceed 100 characters.'
    } else if (!/^[A-Za-z\s]+$/.test(values.jobrole.trim())) {
      errors.jobrole = 'Job role can only contain letters.'
    }
    // Job Type Validation
    if (!values.jobtype?.Id) {
      errors.jobtype = 'Please select a job type.'
    }

    // Job Type Validation
    if (!values.locationname?.Id) {
      errors.locationname = 'Please select a location name.'
    }

    const jobdescriptionText = values.jobdescription
      .toString('html')
      .replace(/<[^>]+>/g, '')
      .trim()
    if (!jobdescriptionText) {
      errors.jobdescription = 'Please enter the job description.'
    } else if (jobdescriptionText.split(/\s+/).length < 5) {
      errors.jobdescription = ' Job Description must be at least 5 words.'
    } else if (jobdescriptionText.split(/\s+/).length > 1000) {
      errors.jobdescription = ' Job Description must not exceed 70 words.'
    }

    // Skills Validation
    if (!values.skills || values.skills.length === 0) {
      errors.skills = 'Please add at least one skills.'
    }

    const findDuplicates = (arr) => {
      const counts = arr.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1
        return acc
      }, {})

      return Object.keys(counts).filter((key) => counts[key] > 1)
    }

    const duplicates = findDuplicates(values.skills)
    if (duplicates.length > 0) {
      errors.skills = 'Skill already exists!'
    }

    return errors
  }

  const handleSubmit = async (e) => {
    if (e.key === 'Enter') {
      return
    } else {
      e.preventDefault()
      setisSaveClick(true)
      const errorval = validateForm(formData)
      setErrors(errorval)

      if (Object.keys(errorval).length > 0) {
        return
      }
      try {
        if (decryptedSeoId != null) {
          const res = formData?.skills.join(', ')
          const Editjobs = {
            RowKey: rowKey,
            jobTitle: formData?.jobTitle,
            jobRole: formData?.jobrole,
            jobType: formData?.jobtype?.Name,
            Location: formData?.locationname?.Name,
            jobDescription: formData?.jobdescription?._cache.html,
            Skills: res,
            Status: formData?.status,
            IsActive: true,
          }
          setLoading(true)
          await getData(
            APIEndPoints.UpdateJobDetails.method,
            APIEndPoints.UpdateJobDetails.url,
            Editjobs
          )
            .then((resp) => {
              if (resp && resp.data) {
                setErrors({})
                setisSaveClick(false)
                const { message, status } = resp
                if (status === 200) {
                  setToastOpen(true)
                  setToastShown({
                    success: true,
                    message: resp.data.message,
                  })
                  setFormData({
                    jobTitle: '',
                    jobrole: { Id: '', Name: '' },
                    jobtype: { Id: '', Name: '' },
                    locationname: { Id: '', Name: '' },
                    jobdescription: ReactRTE.createEmptyValue(),
                    skills: [],
                    status: '',
                  })
                  navigate('/Login')
                  setLoading(false)
                }
                if (statusCode === 409) {
                  setToastOpen(true)
                  setToastShown({
                    success: false,
                    pageNamealready: true,
                    message: resp.data.message,
                  })
                  return
                }
                if (statusCode !== 200 && statusCode !== 409) {
                  setGlobalError(statusCode)
                }
              }
            })
            .catch((err) => {
              setGlobalError(202)
            })
        } else {
          const skillsstring = formData.skills.join(',')

          const createjobs = {
            jobTitle: formData.jobTitle,
            JobRole: formData.jobrole,
            jobType: formData.jobtype.Name,
            Location: formData.locationname.Name,
            Skills: skillsstring,
            JobDescription: formData.jobdescription._cache.html,
            Status: formData.status,
            IsActive: true,
          }
          setLoading(true)
          await getData(
            APIEndPoints.PostJobDetails.method,
            APIEndPoints.PostJobDetails.url,
            createjobs
          )
            .then((resp) => {
              if (resp && resp.data) {
                setErrors({})
                setisSaveClick(false)
                const { message, status } = resp
                if (status === 200) {
                  setToastOpen(true)
                  setToastShown({
                    success: true,
                    message: resp.data.message,
                  })

                  setFormData({
                    jobTitle: '',
                    jobrole: { Id: '', Name: '' },
                    jobtype: { Id: '', Name: '' },
                    locationname: { Id: '', Name: '' },
                    jobdescription: ReactRTE.createEmptyValue(),
                    skills: [],
                    status: '',
                  })
                  setLoading(false)
                  navigate('/Login')
                }
                if (statusCode === 409) {
                  setToastOpen(true)
                  setToastShown({
                    success: false,
                    pageNamealready: true,
                    message: resp.data.message,
                  })
                  return
                }
                if (statusCode !== 200 && statusCode !== 409) {
                  setGlobalError(statusCode)
                }
              }
            })
            .catch((err) => {
              setGlobalError(202)
            })
        }
      } catch (error) {
        console.error('Error submitting form:', error)
      }
    }
  }

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false)
  }

  const handleConfirmDelete = async () => {
    setLoading(true)

    if (confirmDialogAction == 'singledelete') {
      const DeleteJobrole = {
        userId: userId,
        id: deleteid,
      }
      await getData(
        APIEndPoints.DeleteJobRoleDetails.method,
        APIEndPoints.DeleteJobRoleDetails.url,
        DeleteJobrole
      )
        .then((resp) => {
          if (resp && resp.data) {
            const { message, statusCode } = resp.data
            setLoading(false)
            setConfirmDialogOpen(false)
            setDeletedid(null)
            setjobroleTrigger(true)
            if (statusCode === 200) {
              setToastOpen(true)
              setToastShown({
                success: true,
                message: resp.data.message,
              })
            }
            if (statusCode === 409) {
              setToastOpen(true)
              setToastShown({
                success: false,
                pageNamealready: true,
                message: resp.data.message,
              })
              return
            }
            if (statusCode !== 200 && statusCode !== 409) {
              setGlobalError(statusCode)
            }
          }
        })
        .catch((err) => {
          setLoading(false)
          setDeletedid(null)
          setGlobalError(202)
        })
    } else {
      const Deleteids = {
        userId: userId,
        id: selectedRowIds,
      }
      await getData(
        APIEndPoints.DeleteAllJobRoleDetails.method,
        APIEndPoints.DeleteAllJobRoleDetails.url,
        Deleteids
      )
        .then((resp) => {
          if (resp && resp.data) {
            setLoading(false)
            setSelectedRowIds([])
            setConfirmDialogOpen(false)
            setSelectedRowIds([])
            setjobroleTrigger(true)
            const { message, statusCode } = resp.data
            if (statusCode !== 200 && statusCode !== 409) {
              setGlobalError(statusCode)
            }
            if (statusCode === 200) {
              if (
                message ===
                'Only Non-Associated Job Roles deleted successfully.'
              ) {
                setToastOpen(true)
                setToastShown({
                  success: true,
                  message: resp.data.message,
                })
              } else {
                setToastOpen(true)
                setToastShown({
                  success: false,
                  pageNamealready: true,
                  message: resp.data.message,
                })
              }
            }

            if (statusCode === 409) {
              setToastOpen(true)
              setToastShown({
                success: false,
                pageNamealready: true,
                message: resp.data.message,
              })
              return
            }
          }
        })
        .catch((err) => {
          setLoading(false)
          setGlobalError(202)
          setSelectedRowIds([])
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>Create New Job</title>
        <meta name="description" content="This is Page built with React!" />
        <meta property="og:title" content="Create New Job" />
        <meta
          property="og:description"
          content="This is Page built with React!"
        />
        <meta
          property="og:image"
          content="https://lvsitecdn.azureedge.net/Images/logicvalley-headerlogo.svg"
        />
        <main>
          <h1>Logicvalley-CreateNewJobs</h1>
        </main>
      </Helmet>
      <div>
        {PaneContent && (
          <Slider
            SliderComponent={
              <CreateJobRole
                setPanContent={setPanContent}
                setPanStatus={setPanStatus}
                setToastOpen={setToastOpen}
                setToastShown={setToastShown}
                setDeletedid={setDeletedid}
                setConfirmDialogOpen={setConfirmDialogOpen}
                setjobroleTrigger={setjobroleTrigger}
                jobroleTrigger={jobroleTrigger}
                setSelectedRowIds={setSelectedRowIds}
                selectedRowIds={selectedRowIds}
                setConfirmDialogAction={setConfirmDialogAction}
                getjobRole={getjobRoleDetails}
              />
            }
          />
        )}
        <ConfirmationDialog
          open={confirmDialogOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title={'Are you sure you want to delete this Job Role?'}
        />
        {toastOpen && toastShown.success && (
          <ToastMessage
            statusMassage="SUCCESS !"
            contentMessage={toastShown.message}
          />
        )}
        {toastOpen && toastShown.pageNamealready && (
          <ToastMessage
            statusMassage="Error Occured"
            contentMessage={toastShown.message}
            unsuccessStatus
          />
        )}
        <div className="create-newJob-main">
          <div className="newJob-container">
            <Grid2 container spacing={8}>
              <Grid2 item size={6}>
                <Grid2 container spacing={1}>
                  <Grid2 item size={12}>
                    <Typography variant="h5" fontWeight={400} color="#42425A">
                      {decryptedSeoId != null ? 'Update Job' : 'New Job'}
                    </Typography>
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <TextBox
                      placeholder="Enter job title"
                      label="Job Title"
                      fieldName="title"
                      className="titleContainer"
                      value={formData.jobTitle}
                      onchangeHandler={handleJob}
                      error={errors.jobTitle}
                      // errorText={errors.jobTitle}
                      maxLength={50}
                    />
                    {errors.jobTitle && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.jobTitle}
                      </div>
                    )}
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <TextBox
                      placeholder="Enter job Role"
                      label="Job Role"
                      fieldName="role"
                      className="roleContainer"
                      value={formData.jobrole}
                      onchangeHandler={handleJobRoleChange}
                      error={errors.jobrole}
                      // errorText={errors.jobrole}
                      maxLength={50}
                    />
                    {errors.jobrole && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.jobrole}
                      </div>
                    )}
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <AutoCompleteComponent
                      options={jobtypeList}
                      label="Job Type"
                      placeholder="Select job type"
                      initialValue={{
                        Name: formData.jobtype?.Name,
                      }}
                      selectChangeHandler={handleJobTypeChange}
                      error={errors?.jobtype}
                      size="small"
                    />
                    {errors.jobtype && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.jobtype}
                      </div>
                    )}
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <AutoCompleteComponent
                      options={locationDetail}
                      label="Location Name"
                      placeholder="Select location name"
                      initialValue={{
                        Name: formData.locationname?.Name,
                      }}
                      selectChangeHandler={handleLocationName}
                      error={errors?.locationname}
                      size="small"
                    />
                    {errors.locationname && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.locationname}
                      </div>
                    )}
                  </Grid2>
                  <Grid2
                    item
                    size={12}
                    sx={{ mt: 3 }}
                    className="buttonContainer"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        color: '#2C95B5',
                        fontWeight: '500',
                        backgroundColor: '#005C84',
                        color: '#fff',
                        borderRadius: 2,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                        }
                      }}
                      onClick={handleSubmit}
                    >
                      {decryptedSeoId != null ? 'Update Job' : 'Create New Job'}
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => navigate(-1)}
                      className="backButton"
                    >
                      BACK
                    </Button>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 item size={6}>
                <Grid2 container spacing={1}>
                  <Grid2 item size={12} sx={{ mt: 6.5 }}>
                    <GlobalEditor
                      name="JobDescription"
                      value={formData.jobdescription}
                      onChange={handleTextEditor}
                      label={'Job Description'}
                      error={errors.jobdescription}
                    />
                    {errors.jobdescription && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.jobdescription}
                      </div>
                    )}
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <div class="autolabel-name">{'Skills'}</div>
                    <MuiChipsInput
                      fullWidth
                      placeholder="Add Skills"
                      size="small"
                      value={formData.skills}
                      onChange={handleChangeChip}
                      onDeleteChip={(deletedValue) => {
                        const updatedSkills = [...formData.skills]

                        // Remove only the first occurrence of deletedValue
                        const index = updatedSkills.indexOf(deletedValue)
                        if (index !== -1) {
                          updatedSkills.splice(index, 1)
                        }

                        // Function to find duplicates
                        const findDuplicates = (arr) => {
                          const counts = arr.reduce((acc, val) => {
                            acc[val] = (acc[val] || 0) + 1
                            return acc
                          }, {})

                          return Object.keys(counts).filter(
                            (key) => counts[key] > 1
                          )
                        }

                        const duplicates = findDuplicates(updatedSkills)

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          skills:
                            duplicates.length > 0
                              ? `Skill already exists!`
                              : '',
                        }))

                        // Update form data
                        setFormData((prev) => ({
                          ...prev,
                          skills: updatedSkills,
                        }))
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault()

                          const enteredValue = event.target.value.trim()
                          if (enteredValue === '') return

                          // Check if skill already exists
                          if (formData.skills.includes(enteredValue)) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              skills: `Skill already exists!`,
                            }))
                          } else {
                            // Add the new skill to the list
                            const updatedSkills = [
                              ...formData.skills,
                              enteredValue,
                            ]

                            // Function to find duplicates
                            const findDuplicates = (arr) => {
                              const counts = arr.reduce((acc, val) => {
                                acc[val] = (acc[val] || 0) + 1
                                return acc
                              }, {})
                              return Object.keys(counts).filter(
                                (key) => counts[key] > 1
                              )
                            }

                            const duplicates = findDuplicates(updatedSkills)

                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              skills:
                                duplicates.length > 0
                                  ? `Skill already exists!`
                                  : '',
                            }))

                            // Update form data
                            setFormData((prev) => ({
                              ...prev,
                              skills: updatedSkills,
                            }))
                          }
                        }
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.skills
                              ? 'rgb(211, 47, 47) !important'
                              : '#ccc',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.skills
                              ? 'rgb(211, 47, 47) !important'
                              : '#888',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.skills
                              ? 'rgb(211, 47, 47) !important'
                              : '#1976d2',
                          },
                        },
                      }}
                    />

                    {errors.skills && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                          marginTop: '5px',
                          marginBottom: '3px',
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {errors.skills}
                      </div>
                    )}
                  </Grid2>
                  <Grid2 item size={12} sx={{ mt: 2 }}>
                    <div class="autolabel-name">{'Status'}</div>
                    <ToggleButtonGroup
                      value={formData.status}
                      exclusive
                      onChange={handleChange}
                      aria-label="status"
                      size="small"
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        display: 'contents',
                      }}
                    >
                      {statusOptions.map((option) => (
                        <ToggleButton
                          key={option?.status}
                          value={option?.status}
                          className="statusToggleContainer"
                          sx={{
                            flex: 1,
                            textTransform: 'none',
                            fontSize: '12px',
                            padding: '6px 18px',
                            '&.Mui-selected': {
                              backgroundColor: '#e4e4e7',
                              color: '#000',
                            },
                          }}
                        >
                          {option.status}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateNewJobs
