/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This is the App starting file of the application.
  StrictMode  : StrictMode is a tool for highlighting potential problems in an application. Like Fragment , 
                StrictMode does not render any visible UI. It activates additional checks and warnings for its descendants.
*/

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App'
import { ThemeProvider } from '@mui/material'
import theme from './views/components/custom/themeProvider/ThemeProvider'
import { RecoilRoot } from 'recoil'
import ConfigLoader from './configs/ConfigLoader'
import AOS from 'aos'
import 'aos/dist/aos.css' // This imports the necessary styles for AOS
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './../src/app/msalConfig'
AOS.init({})
let inactivityTimeout
const resetAOS = () => {
  clearTimeout(inactivityTimeout)
  inactivityTimeout = setTimeout(() => {
    AOS.refresh() // Refresh AOS animations after inactivity
  }, []) // Adjust this time as needed
}

// Detect user activity
window.addEventListener('mousemove', resetAOS)
window.addEventListener('scroll', resetAOS)
window.addEventListener('keypress', resetAOS)

// Optionally, you can add AOS refresh when the page initially loads
window.addEventListener('load', () => {
  AOS.refresh()
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RecoilRoot>
    {/* <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider> */}

    <ThemeProvider theme={theme}>
      <ConfigLoader
        ready={(props) => {
          return (
            <MsalProvider instance={msalInstance}>
              <App appConfig={props} />
            </MsalProvider>
          )
        }}
      />
    </ThemeProvider>
  </RecoilRoot>
)
