// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  color: red;
}

.backdrop.MuiBackdrop-root {
  z-index: 2009 !important;
}

.loadingControl {
  height: 100%;
  width: 100%;
  z-index: 2010;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/loadingcomponent/LoadingComponent.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;AACf","sourcesContent":[".backdrop {\n  color: red;\n}\n\n.backdrop.MuiBackdrop-root {\n  z-index: 2009 !important;\n}\n\n.loadingControl {\n  height: 100%;\n  width: 100%;\n  z-index: 2010;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
